import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import './FileLister.css';

const FileLister = ({ files }) => {
	const downloadFile = async (url, name) => {
		const res = await fetch(url);
		const blob = await res.blob();
		console.log('df')

		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(new Blob([blob]));
		link.setAttribute('download', name);

		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	}

	return (
		<div className="FileLister">
			<table>
				<tbody>
					{files.map(o => 
						<tr key={ o.name} >
							<td>
								<a href={o.url} target='_blank'>
									<p className='file-name'>{o.name}</p>
								</a>
							</td>
							<td onClick={ () => downloadFile(o.url, o.name) } className='file-download-td'>
								<FontAwesomeIcon className='file-download' icon={faDownload} />
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default FileLister;