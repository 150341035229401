import { initializeApp } from 'firebase/app';

export default function getFirebase() {
	const firebaseConfig = {
			apiKey: "AIzaSyABE-VnAV4MOnIzoNreRHur9hXb2547D_U",
			authDomain: "filerna.firebaseapp.com",
			projectId: "filerna",
			storageBucket: "filerna.appspot.com",
			messagingSenderId: "670648976868",
			appId: "1:670648976868:web:80c23822cb613a7a1b73f1"
	};

	return initializeApp(firebaseConfig);
}