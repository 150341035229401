import { useRef } from "react";

import './Input.css';
import waves from './layered-waves.svg';

const Input = ({ id, showFiles }) => {
	const parentRef = useRef(null);
	const inputRef = useRef(null);
	const headingRef = useRef(null);

	const clickHandler = async () => {
		headingRef.current.innerText = 'Accessing files...';

		const res = await fetch('https://getlocation-s2he2awqsa-uc.a.run.app', {
			method: 'POST',
			body: JSON.stringify({
				id,
				input: inputRef.current.value
			})
		});

		if (res.status === 200) {
			const resBody = await res.json();
			parentRef.current.style.opacity = '0';
			setTimeout(() => parentRef.current.style.display = 'none', 150);
			showFiles(resBody.location);
		} else {
			headingRef.current.innerText = 'Incorrect password.';
			inputRef.current.value = '';
			inputRef.current.classList.add('pass-shake');

			setTimeout(() => inputRef.current.classList.remove('pass-shake'), 500);
		}
	}
	
	return (
		<div ref={ parentRef } style={{ backgroundImage: `url(${waves})` }} className="Input">
			<div>
				<p ref={ headingRef }>The files are password protected.</p>
				<input ref={ inputRef } id="pass-field" type="password" placeholder="Password" />
				<br />
				<button onClick={ clickHandler }>Continue</button>
			</div>
		</div>
	);
}

export default Input;