import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Upload from './pages/Upload';
import Display from './pages/Display';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Upload />} />
          <Route path='*' element={<Display />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
