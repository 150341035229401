import FileSelector from '../FileSelector';
import waves from './DisplayComponents/layered-waves.svg';

import './UploadComponents/Upload.css';

import { useRef, useState } from 'react';

import getFirebase from '../getFirebase';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const storage = getStorage(getFirebase());

const Upload = () => {
	let bytes = 0;

	const messageRef = useRef(null);
	const checkboxRef = useRef(null);
	const passRef = useRef(null);

	const [files, setFiles] = useState([]);
	const [fileNames, setFileNames] = useState([]);
	const [filesUploaded, setFilesUploaded] = useState(0);
	const [uiHasEnded, setUiHasEnded] = useState(false);
	const [hasStartedUpload, setHasStartedUpload] = useState(false);
	const [hasCompletedUpload, setHasCompletedUpload] = useState(false);
	const [id, setId] = useState(null);

	const childToParent = fileSet => {
		let hasReachedLimit = false;
		const fileArr = [];

		for (let i = 0; i < fileSet.length; i++) {
			const f = fileSet.item(i);

			if (f.type !== '') {
				if (f.size + bytes > 10e7) {
					hasReachedLimit = true;
				} else {
					bytes += f.size;
					fileArr.push(f);
				}
			}
		}

		console.log(bytes / 10e8, bytes > 10e8)

		setFiles([...files, ...fileArr]);
		setFileNames([...fileNames, ...fileArr.map(f_ => f_.name)]);

		if (hasReachedLimit) showMessage('Maximum limit of 100MB reached', '#ff4e4e');
	}

	const clickHandler = async () => {
		if (!fileNames.length) return;

		setUiHasEnded(true);

		const hasPass = checkboxRef.current.checked;

		const entry = await fetch('https://createentry-s2he2awqsa-uc.a.run.app', {
			method: 'POST',
			body: JSON.stringify({ 
				fileNames,
				hasPass,
				pass: passRef.current.value
			})
		});

		if (entry.status === 200 && fileNames.length) {
			const res = await entry.json();

			setId(res.id);
			setHasStartedUpload(true);

			let index = 0;

			const fileUpload = () => {
				const file = files[index];
				const folderName = hasPass ? res.hashedLocation : res.id;
				const fileRef = ref(storage, `${folderName}/${file.name}`);

				uploadBytes(fileRef, file).then(() => {
					setFilesUploaded(index + 1);

					if (index < fileNames.length - 1) {
						index++;
						fileUpload();
					} else {
						setTimeout(() => setHasCompletedUpload(true), 500);
					}
				}).catch(err => console.error(err));
			}

			fileUpload();
		}
	}

	const showMessage = (text, color) => {
		messageRef.current.innerText = text;

		if (color) messageRef.current.style.backgroundColor = color;

		messageRef.current.style.display = 'block';

		setTimeout(() => {
			messageRef.current.style.opacity = '1';
		}, 100);

		setTimeout(() => {
			messageRef.current.style.opacity = '0';

			setTimeout(() => {
				messageRef.current.style.display = 'none';
			}, 200);
		}, 2500);
	}

	const passToggle = () => {
		if (checkboxRef.current.checked)
			passRef.current.removeAttribute('disabled');
		else
			passRef.current.setAttribute('disabled', '');
	}

	const copyLink = () => {
		navigator.clipboard.writeText(`https://filerna.com/${id}`);
		showMessage('Copied!', '#6600FF');
	}

	return (
		<div className="Upload">
			<div ref={ messageRef } className='message'></div>

      <FileSelector childToParent={ childToParent } />

			<div style={{ display: fileNames.length ? 'block' : 'none' }} className='file-name-container'>
      	{(fileNames.map(f => <div key={f}>{f}</div>))}
			</div>

			<div className='input-container'>
				<div className='checkbox-container'>
					<p>Use password?</p>
					<input onChange={ passToggle } ref={ checkboxRef } type="checkbox" />
				</div>
				<input disabled className='password-field' ref={ passRef } type="text" placeholder="Password" />
				<button className={`submit-button ${fileNames.length ? 'submit-ready' : ''}`} onClick={ clickHandler }>Create link</button>
			</div>

			<div style={{ display: uiHasEnded ? 'block' : 'none' }} className='uploading-container'>
				<p style={{ display: uiHasEnded && !hasCompletedUpload && !hasStartedUpload ? 'block' : 'none' }}>
					Preparing upload...
				</p>
				<p style={{ display: hasStartedUpload && !hasCompletedUpload ? 'inline-block' : 'none' }}>
					Uploaded { filesUploaded }/{ fileNames.length } file{ fileNames.length === 1 ? '' : 's' }
				</p>
				<p style={{ display: hasCompletedUpload ? 'inline' : 'none' }}>
					<a href={ `https://filerna.com/${id}` } target='_blank'>
						{ `https://filerna.com/${id}` }
					</a>
					<FontAwesomeIcon onClick={ copyLink } className='display-header-icon' icon={ faCopy } />
				</p>
			</div>

			<div style={{ backgroundImage: `url(${waves})` }} className='bg'></div>
    </div>
	);
}

export default Upload;