import './FileSelector.css';

import { useRef, useState } from 'react';

const FileSelector = ({ childToParent }) => {
	const uploadBorderRef = useRef(null);
	const uploadBorderContentRef = useRef(null);
	const uploadAddRef = useRef(null);

	const [ uploadBorderContentOpacity, setUploadBorderContentOpacity ] = useState('1');
	const [ uploadAddOpacity, setUploadAddOpacity ] = useState('0');

	const fileHandler = e => {
    let files;

    if (e.dataTransfer) files = e.dataTransfer.files;
    else files = e.target.files;

		childToParent(files);
  }

	const prevention = e => {
		e.preventDefault();
		e.stopPropagation();
	}
	
	const showAdd = e => {
		prevention(e);

		setUploadBorderContentOpacity('0');
		setUploadAddOpacity('1');
	}

	const hideAdd = e => {
		prevention(e);

		setUploadBorderContentOpacity('1');
		setUploadAddOpacity('0');
	}

	const dropHandler = e => {
		prevention(e);
		fileHandler(e);
		hideAdd(e);
	}

	return (
		<div id="upload-container">
			<div id="upload-border" ref={ uploadBorderRef } onDrop={ dropHandler } onDragEnter={ showAdd } onDragOver={ showAdd } onDragLeave={ hideAdd }>
					<div style={{ opacity: uploadAddOpacity }} id="upload-add" ref={ uploadAddRef }></div>
					<div style={{ opacity: uploadBorderContentOpacity }} id="upload-border-content" ref={ uploadBorderContentRef }>
							<p>Drop files here<br /><span>or</span></p>
							<label>
									<input onChange={ fileHandler } id="upload-input" type="file" multiple />
									Browse files
							</label>
					</div>
			</div>
		</div>
	);
}

export default FileSelector;