import Input from './DisplayComponents/Input';
import FileLister from './DisplayComponents/FileLister';

import './DisplayComponents/Display.css';
import waves from './DisplayComponents/layered-waves.svg';

import JSZip from 'jszip';

import getFirebase from '../getFirebase';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useState, useEffect } from 'react';

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';

const db = getFirestore(getFirebase());
const storage = getStorage(getFirebase());

const Display = () => {
	const [files, setFiles] = useState([]);
	const [zipBlob, setZipBlob] = useState(null);
	const [isReady, setIsReady] = useState(false);
	const [hasPass, setHasPass] = useState(null);
	const [pageExists, setPageExists] = useState(true);

	const id = window.location.pathname.slice(1);

	getDoc(doc(db, 'entries', id)).then(async snap => {
		if (snap.exists()) {
			const data = snap.data();
			if (data.hasPass) {
				setHasPass(true);
			} else {
				setHasPass(false);
			}
		} else {
			setPageExists(false);
			setIsReady(true);
		}
	});

	const showFiles = async location => {
		const folderRef = ref(storage, location);

		const res = await listAll(folderRef);

		const fileArray = [];
		
		const jszip = new JSZip();
		let index = 0;
		res.items.forEach(async itemRef => {
			const url = await getDownloadURL(ref(storage, itemRef.fullPath));
			fileArray.push({ name: itemRef.name, url });

			const blob = await fetch(url).then((response) => response.blob());
			jszip.file(itemRef.name, blob);

			if (index === res.items.length - 1) {
				setFiles(fileArray.sort((a, b) => a.name.localeCompare(b.name)));
				setZipBlob(await jszip.generateAsync({ type: 'blob' }));

				setIsReady(true);
			}
			else index++;
		});
	}

	useEffect(() => {
		if (!hasPass) showFiles(id);        
    },[hasPass]);

	const downloadZip = () => {
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(new Blob([zipBlob]));
		link.setAttribute('download', 'filerna_download.zip');

		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	}
	
	return (
		<div className="Display">
			<div style={{ display: pageExists ? 'none' : 'block' }} className='no-page'>
				<p>The page does not exist.</p>
			</div>

			<div style={{ display: isReady ? 'none' : 'block' }} className='display-loader'>
				<FontAwesomeIcon icon={ faSpinner } />
			</div>

			<div style={{ display: isReady && pageExists ? 'block' : 'none', marginTop: '5rem' }}>
				<div className='display-header'>
					<h1>File directory.</h1>
					<span>|</span>
					<button style={{ display: zipBlob === null ? 'none' : 'inline' }} onClick={ downloadZip }>
						Download zip <FontAwesomeIcon className='display-header-icon' icon={ faDownload } />
					</button>
				</div>
				
				<FileLister files={ files } />

				<br />
				<br />
			</div>

			<div style={{ display: hasPass === true ? 'block' : 'none' }}>
				<Input id={ id } showFiles={ showFiles } />
			</div>

			<div style={{ backgroundImage: `url(${waves})` }} className='bg'></div>
		</div>
	);
}

export default Display;